@font-face {
  font-family: 'Lato-Bold';
  src: local('Lato'), url('./assets/fonts/Lato-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('./assets/fonts/Lato-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Lato-Light';
  src: local('Lato'), url('./assets/fonts/Lato-Light.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Lato-Light', 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-family: 'Lato-Bold', 'Roboto', 'Helvetica Neue', sans-serif; 
}