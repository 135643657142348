body {
  line-height: 0;
  background: rgba(25, 22, 29, 1);
}

.social {
  position: absolute;
  bottom: 0;

  height: 20%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.social img {
  color: white;
  height: 100%;
  max-height: 50px;
}

.social p {
  font-size: 16px;;
  line-height: 20px;
  color: white;
  display: block;
  margin: 0;
}